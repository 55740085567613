.sidebar-container {
  width: 215px;
  /* Matches w-52 in Tailwind CSS */
  height: 100vh;
  overflow-x: hidden;
  /* Prevents horizontal scrolling */
  overflow-y: auto;
  /* Allows vertical scrolling if needed */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.sidebar-container::-webkit-scrollbar {
  display: none;
}

.sidebar-menu {
  border-right: none !important;
  background: transparent !important;
  overflow-x: hidden;
  font-weight: 500 !important;
  /* Additional safeguard */
}

.sidebar-heading {
  font-size: 14px !important;
  color: #1643f9 !important;
  font-weight: 700 !important;
  padding: 8px 16px !important;
  margin: 0 !important;
  line-height: 1.5 !important;
}

.ant-menu-item .ant-menu-title-content {
  white-space: normal !important;
  word-wrap: break-word !important;
  line-height: 1.2 !important;
}

.ant-menu-submenu-title .ant-menu-title-content {
  white-space: normal !important;
  word-wrap: break-word !important;
  line-height: 1.2 !important;
}

.sidebar-link {
  padding: 6px 16px 6px 32px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #1a1b1c !important;
  border-radius: 4px !important;
  margin: 2px 0 !important;
}

.sidebar-link:hover,
.sidebar-link:focus {
  background-color: #e5e7eb !important;
  color: #2563eb !important;
}

.sidebar-icon {
  font-size: 14px !important;
  margin-right: 8px !important;
}

.sidebar-footer {
  padding: 6px 16px;
  position: sticky;
  bottom: 0;
}

.sidebar-logout {
  padding: 6px 16px !important;
  font-size: 12px !important;
  color: white !important;
  background-color: #ef4444 !important;
  border-radius: 4px !important;
  margin: 2px 0 !important;
}

.sidebar-logout:hover,
.sidebar-logout:focus {
  background-color: #dc2626 !important;
  color: white !important;
}