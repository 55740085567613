/* @media print {
    #printableContent {
      margin: 0 auto;
      text-align: center;
    }
  
    .container {
        width: 100%;
        max-width: none;
        margin: 0 auto;
      }
  }

.print-content {
    text-align: center;
    margin: 0 auto;
} */

/* Print Styles */
@media print {
  body {
    body {
      background-image: url('/public/tulasi_small_logo.png');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center top;
      background-size: 100px;
      -webkit-print-color-adjust: exact;
    }
  }

  #printableContent {
    margin: 0 auto;
    text-align: center;
    max-width: 100% !important;
    line-height: 1.4;
    font-size: 12px;
  }

  .container {
    width: 100%;
    max-width: none;
    margin: 0 auto;
  }

  .print-content {
    text-align: left;
    margin: 0 auto;
    max-width: 700px;
  }

  .print-content .ant-table-small {
    font-size: 10px;
  }

  .print-content h3 {
    font-size: 14px;
    margin: 10px 0;
  }

  .print-content p {
    margin: 5px 0;
    line-height: 1.2;
  }

  .print-content .ant-table-wrapper {
    margin-bottom: 12px;
  }
}